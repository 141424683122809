import { Button } from 'react-bulma-components';
import { faCamera, faFileImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayData } from './MCULayoutOverlay';
import styles from '../../styles/clientoverlay.module.css';

type Props = {
    overlay: OverlayData;
    onScreenshotClick: (userId: string) => void;
    onUploadClick: (userId: string) => void;
};

export default function ClientOverlay({ overlay, onScreenshotClick, onUploadClick }: Props) {
    function isHoloLens() {
        console.log("-----------here-------------");
        return overlay.roles && overlay.roles.includes("hololens");
    }

    return (
        <div style={{ zIndex: 9, top: overlay.top, left: overlay.left }} className={styles.client}>
            <div >
                <p style={{fontSize: "18px"}} className={styles.name}>{overlay.userAlias}</p>
            </div>
            {/* <div style={{ bottom: overlay.bottom, left: overlay.left }} className={styles.client}> */}
                {isHoloLens() &&
                    <>
                        <Button className={styles.button} rounded color="link" style={{ zIndex:10, borderRadius: "10px", padding: "1em 1.25em" }} onClick={() => onScreenshotClick(overlay.userId)}>
                            <FontAwesomeIcon icon={faCamera} />
                        </Button>
                        {/* <Button className={styles.button} rounded color="primary" onClick={() => onUploadClick(overlay.userId)}>
                            <FontAwesomeIcon icon={faFileImage} />
                        </Button> */}
                    </>
                }
            {/* </div> */}
        </div>
    );
}