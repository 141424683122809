import 'bulma/css/bulma.min.css';
import '@creativebulma/bulma-tooltip/dist/bulma-tooltip.min.css';
import '../index.css' // Has to be imported after bulma to override
import { useState, useEffect } from "react";
import { Form, Button, Heading, Box, Navbar } from "react-bulma-components";
import { useNavigate } from "react-router-dom";
import httpRequest from '../js/utils/httpRequest';
import generateUserAlias from '../js/utils/UserAliasGenerator';
import LastJoinedChannels from '../components/home/LastJoinedChannels';
import styles from '../styles/box.module.css';
import holorayLogo from '../assets/images/png/logo.png';
import bgMain from '../assets/images/bg.jpg';

export default function Home() {
    const [userAlias, setUserAlias] = useState("");
    const [channelId, setChannelId] = useState("");

    let navigate = useNavigate();

    useEffect(() => {
        setUserAlias(localStorage.getItem('userAlias') || generateUserAlias());

        onJoinChannelClick("76a8358b")

    }, []);
    

    function onJoinChannelClick(id = channelId) {
        if (!id) {
            return;
        }

        localStorage.setItem('userAlias', userAlias);

        navigate(`/channel/${id}`);
    }

    function onCreateChannelClick() {
        localStorage.setItem('userAlias', userAlias);

        httpRequest.getNewChannel(id => {
            navigate(`/channel/${id}`);
        });
    }

    return (
        <>
            {/* <Navbar style={{ marginBottom: "0px", padding: "5px 20px", boxShadow: "0px 0px 10px black"}}>
                <Navbar.Brand>
                    <Navbar.Item renderAs='span'>
                    <img
                        alt="HOLORAY"
                        src={holorayLogo}
                    />
                    </Navbar.Item>
                </Navbar.Brand>
            </Navbar> */}
            <div style={ {
        display: 'flex',
        alignItems: 'center',
        backgroundImage: `url(${bgMain})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        justifyContent: 'flex-end', // Moves content to the right
        width: '100%'
      } }>
                <Box className={styles.box}>
                    <form>
                        <div style={{textAlign: "center"}}>
                            <img
                                alt="HOLORAY"
                                src={holorayLogo}
                                style={{width: "85%"}}
                            />
                        </div>
                        {/* <Heading>Hello!</Heading> */}
                        <Form.Field>
                            <Form.Label>Your name</Form.Label>
                        </Form.Field>
                        <Form.Field>
                            <Form.Control>
                                <Form.Input type="text" placeholder="Type your name here" onChange={({ target }) => setUserAlias(target.value)} value={userAlias}></Form.Input>
                            </Form.Control>
                        </Form.Field>
                        <Form.Field>
                            <Form.Label>Channel id</Form.Label>
                        </Form.Field>
                        <Form.Field>
                            <Form.Control>
                                <Form.Input className={!channelId ? "is-danger" : ""} type="text" placeholder="Enter a channel ID" onChange={({ target }) => setChannelId(target.value)}></Form.Input>
                            </Form.Control>
                        </Form.Field>
                        <Button style={{marginBottom: "25px"}} fullwidth color="link" onClick={() => onJoinChannelClick()}>Join</Button>
                        {/* <Form.Field style={{ marginTop: '20px', marginBottom: '20px' }}>
                            <Form.Label textAlign='center'>OR</Form.Label>
                        </Form.Field>
                        <Button rounded fullwidth color="primary" onClick={onCreateChannelClick}>Create a new channel</Button> */}
                    </form>
                </Box>
                {/* <LastJoinedChannels onJoinChannelClick={(id) => onJoinChannelClick(id)} /> */}
            </div>
        </>
    );
}