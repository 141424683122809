import { useEffect, useRef, useState } from "react";
import { Button } from "react-bulma-components";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChannelConnection from "../../js/connection/ChannelConnection";
import styles from "../../styles/chatbox.module.css";

type Props = {
    connection: ChannelConnection | undefined
};

export default function ChatBox({ connection }: Props) {
    const [chatOpened, setChatOpened] = useState(false);
    const chatBox = useRef<HTMLDivElement>(null);
    const input = useRef<HTMLInputElement>(null);

    useEffect(() => {
        function writeMessage({ user, message }: {user: string, message: string}) {
            const content = document.createElement("p");
    
            content.innerHTML = `<b>${user}: </b>${message}`;
            if (chatBox.current !== null) {
                chatBox.current.appendChild(content);
                chatBox.current.scrollTop = chatBox.current.scrollHeight;
            }
        }

        document.addEventListener("keydown", onKeydown);
        connection?.events.addListener("onmessage", writeMessage);
        return () => {
            document.removeEventListener("keydown", onKeydown);
            connection?.events.removeListener("onmessage", writeMessage);
        }
    });

    function onKeydown(event: KeyboardEvent) {
        if (event.key === "Enter") {
            onSendClicked();
        }
    }

    function onSendClicked() {        
        let currentMessage: string = input.current!.value;

        if (currentMessage) {
            connection?.messager.sendChatMessage(currentMessage)?.then(() => {
                currentMessage = "";
                input.current!.value = "";
            });
        }
    }

    return (
        <div id={styles.chatBoxContainer}>
            <Button rounded onClick={() => setChatOpened(!chatOpened)}>
                    <FontAwesomeIcon icon={faCommentDots} />
            </Button>

            <div id={styles.chatBox} ref={chatBox} style={{ "visibility": chatOpened ? "visible" : "hidden" }}>
                {/* Paragraphs will go  here */}
            </div>

            <div className="field has-addons" style={{ "visibility": chatOpened ? "visible" : "hidden" }}>
                <div className="control is-expanded">
                    <input ref={input} className="input" type="text" placeholder="Enter a message" />
                </div>
                <div className="control">
                    <button type="button" className="button" onClick={onSendClicked} style={{fontSize: "unset !important"}}>Send</button>
                </div>
            </div>
        </div>
    );
}