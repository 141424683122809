import Painterro from 'painterro';
import '../../assets/css/PainterroCustomStyles.css';

export default class PainterroUtils {
    #painterro: any = null;
    #sendBtn: any = null;
    #sending = false;
    #isDrawing = false;
    #points: AnnotationPoint[] = [];
    #annotations: Annotation[] = [];
    #redoStack: Annotation[] = [];

    show(image: any, onSendImage: (base64Image: string, annotations: Annotation[]) => void) {
        this.#painterro = Painterro({
            hiddenTools: image !== null ? ['open','text', 'arrow', 'crop', 'eraser', 'select', 'pixelize', 'rect', 'ellipse', 'setting', 'zoomin', 'zoomout', 'open'] : [],
            hiddenControls: {
                lineWidth: true,
                color: false
            },
            defaultTool: 'brush',
            activeColor: '#ff0000',
            saveHandler: (image: any, done: any) => {
                if (this.#sending) {
                    onSendImage(image.asDataURL(), this.#annotations);

                    // done(true) will hide painterro
                    done(true);
                } else {
                    const downloadLink = document.createElement('a');
                    document.body.appendChild(downloadLink);

                    downloadLink.href = image.asDataURL();
                    downloadLink.target = '_self';
                    downloadLink.download = "image.png";
                    downloadLink.click();

                    // done(false) wont hide painterro
                    done(false);
                }
            },
            onUndo: this.#undo.bind(this),
            onRedo: this.#redo.bind(this)
        });

        this.#painterro.show(image !== null ? "data:image/png;base64," + image : null);

        this.#overrideClearButton(image);

        const ptroCrpEl = this.#painterro.canvas.parentElement.querySelector('.ptro-crp-el');
        // Mouse events
        ptroCrpEl?.addEventListener('mousedown', this.#handleStart.bind(this));
        ptroCrpEl?.addEventListener('mousemove', this.#handleMove.bind(this));
        ptroCrpEl?.addEventListener('mouseup', this.#stopDrawing.bind(this));
        ptroCrpEl?.addEventListener('mouseout', this.#stopDrawing.bind(this));
        
        // Touch events
        ptroCrpEl?.addEventListener('touchstart', this.#handleStart.bind(this));
        ptroCrpEl?.addEventListener('touchmove', this.#handleMove.bind(this));
        ptroCrpEl?.addEventListener('touchend', this.#stopDrawing.bind(this));

        this.#appendSendButton();
    }

    #overrideClearButton(image: any) {
        // Prevent the clear button to clear the entire image.
        // Keep the background image instead
        this.#painterro.toolByName.clear.activate = () => {
            this.#painterro.clear();

            this.#annotations = [];
            this.#updateSendButton();
            this.#redoStack = [];
            this.#points = [];

            if (image !== null) {
                this.#painterro.loadImage(`data:image/png;base64,${image}`);
            }

            this.#painterro.closeActiveTool();
        };
    }

    #appendSendButton() {
        const saveBtn = this.#painterro.saveBtn;
        this.#sendBtn = document.createElement("button");
        this.#sendBtn.type = "button";
        this.#sendBtn.className = "ptro-named-btn ptro-color-control";
        this.#sendBtn.style.top = "0px"; // Remove the weird top padding
        this.#sendBtn.innerText = "Send";
        this.#sendBtn.title = "Send image";

        this.#sendBtn.addEventListener("click", () => {
            this.#sending = true;
            this.#painterro.save();
        });

        saveBtn.parentElement.insertBefore(this.#sendBtn, saveBtn);
    }

    #handleStart(e: any) {
        if (e.touches) {
            e.preventDefault();
        }

        this.#points = [];
        console.log(this.#painterro.activeTool.name);
        if (this.#painterro.activeTool.name === 'brush' || this.#painterro.activeTool.name === 'line') {
            this.#isDrawing = true;
        }
    }

    #handleMove(e: any) {
        let viewportX = e.offsetX / e.target.clientWidth;
        let viewportY = e.offsetY / e.target.clientHeight;

        if (e.touches) {
            e.preventDefault();
            const touch = e.touches[0];
            const bounds = e.target.getBoundingClientRect();

            viewportX = (touch.clientX - bounds.left) / touch.target.clientWidth;
            viewportY = (touch.clientY - bounds.top) / touch.target.clientHeight;
        }

        if (this.#isDrawing) {

            const x = viewportX * this.#painterro.canvas.width;
            const y = viewportY * this.#painterro.canvas.height;

            this.#points.push({ x, y });
        }
    }

    #stopDrawing() {
        if (this.#isDrawing) {
            this.#isDrawing = false;
    
            const colorHex = this.#painterro.colorWidgetState.line.palleteColor;

            this.#annotations.push({
                points: this.#points,
                colorHex
            });
            this.#updateSendButton();

            if (this.#redoStack.length > 0) {
                this.#redoStack = [];
            }
        }
    }

    #undo(history: any) {
        if (this.#annotations.length > 0) {
            if (history.next.activeToolName === 'brush' || history.next.activeToolName === 'line') {
                this.#redoStack.push(this.#annotations.pop()!);

                this.#updateSendButton();
            }
        }
    }

    #redo(history: any) {
        if (this.#redoStack.length > 0) {
            if (history.activeToolName === 'brush' || history.activeToolName === 'line') {
                this.#annotations.push(this.#redoStack.pop()!);

                this.#updateSendButton();
            }
        }
    }

    #updateSendButton() {
        const annotationCount = this.#annotations.length;
        const s = this.#annotations.length > 1 ? "s" : "";

        this.#sendBtn.innerText = annotationCount > 0 ? `Send (${annotationCount} annotation${s})` : "Send";
    }
}
