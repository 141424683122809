import liveswitch from "fm.liveswitch";
import generateUserAlias from "../utils/UserAliasGenerator";
import config from "../../liveswitch_config.json"
import ChannelConnectionEvents from "./ChannelConnectionEvents";
import Screenshare from "./Screenshare";
import Messager from "./Messager";

export default class ChannelConnection {
    private localMedia!: liveswitch.LocalMedia;
    private layoutManager!: liveswitch.DomLayoutManager;
    private videoDom!: HTMLElement | null;
    private videoLayout!: liveswitch.VideoLayout;

    private client!: liveswitch.Client;
    private channel!: liveswitch.Channel;

    private connection!: liveswitch.McuConnection;
    
    public screenshare!: Screenshare;
    public messager!: Messager;
    public isLocalVideoEnabled: boolean = true;
    public isLocalAudioEnabled: boolean = true;
    public events: ChannelConnectionEvents = new ChannelConnectionEvents();

    private startLocalMedia() {
        const width = 640;
        const height = 480;
        const fps = 30;

        const videoConfig = new liveswitch.VideoConfig(width, height, fps);
        this.localMedia = new liveswitch.LocalMedia(true, videoConfig, false);
        this.videoDom = document.getElementById("video") as HTMLElement;
        if (!this.videoDom) {
            this.logError("Couldn't find Video element in page");
            return;
        }
        
        this.layoutManager = new liveswitch.DomLayoutManager(this.videoDom);

        return this.localMedia.start();
        // return null; //to disable localMedia//

    }

    public connectToChannel(channelId: string) {
        if (!channelId) {
            console.log("Channel ID to join cannot be empty");
            return;
        }

        console.log(`Attempting to join channel ${channelId}`);

        this.client = new liveswitch.Client(config.gatewayUrl, config.applicationId);
        const userAlias = localStorage.getItem('userAlias') || generateUserAlias();
        this.client.setUserAlias(userAlias);

        let token = liveswitch.Token.generateClientRegisterToken(
            config.applicationId,
            this.client.getUserId(),
            this.client.getDeviceId(),
            this.client.getId(),
            this.client.getRoles(),
            [new liveswitch.ChannelClaim(channelId)],
            config.sharedSecret
        );

        this.client.register(token).then((_) => {
            console.log(`Client ${this.client.getId()} registered`);

            token = liveswitch.Token.generateClientJoinToken(this.client, new liveswitch.ChannelClaim(channelId), config.sharedSecret);

            this.client.join(channelId, token).then((channel) => {
                this.channel = channel;
                console.log(`Successfully joined channel ${channel.getId()} as ${this.client.getUserAlias()}`);

                this.startLocalMedia();
                // this.startLocalMedia()?.then((_) => {
                //     console.log("Local media started");
                // }).fail((ex) => this.logError(`${ex.message}. Make sure your webcam is plugged in and visible by your operating system.`));

                this.openMcuConnection();

            }).fail((ex) => this.logError("Failed to join channel: " + ex.message));

        }).fail((ex) => this.logError("Registration failed: " + ex.message));
    }

    private openMcuConnection() {
        const remoteMedia = new liveswitch.RemoteMedia(true, true);
        this.layoutManager.addRemoteMedia(remoteMedia);

        const audioStream = new liveswitch.AudioStream(this.localMedia, remoteMedia);
        const videoStream = new liveswitch.VideoStream(this.localMedia, remoteMedia);

        this.connection = this.channel.createMcuConnection(audioStream, videoStream);

        this.connection.addOnStateChange(conn => {
            if (conn.getState() === liveswitch.ConnectionState.Closing || conn.getState() === liveswitch.ConnectionState.Failing) {
                this.layoutManager.removeRemoteMedia(remoteMedia);
                remoteMedia.destroy();
            } else if (conn.getState() === liveswitch.ConnectionState.Failed) {
                // Reconnect when the MCU connection failed.
                this.openMcuConnection();
            }
        });

        // Start the screenshare manager
        this.screenshare = new Screenshare(this.channel, this.events, this.logError);

        // Start the message manager
        this.messager = new Messager(this.channel, this.events, this.logError);

        this.channel.addOnMcuVideoLayout(videoLayout => {
            this.videoLayout = videoLayout;
            this.layoutManager.layout();
        });

        this.layoutManager.addOnLayout(_ => this.events.notify("onvideolayout", { videoDom: this.videoDom, layout: this.videoLayout }));

        this.connection.open();
        //this.toggleLocalVideo(false);
    }

    public leaveChannel(): void {
        if (this.client) {
            this.client.leave(this.client.getChannels()[0].getId()).then(_ => {
                console.log("Left channel");

                this.client.unregister().then(_ => {
                    console.log("Unregistered");
                }).fail(ex => this.logError(ex.message));

            }).fail(ex => this.logError(ex.message));
        }

        if (this.localMedia) {
            this.localMedia.stop().then(_ => {
                console.log("Local media stopped");
                this.localMedia.destroy();
            }).fail(ex => this.logError(`Failed to stop local media: ${ex.message}`));
        }

        if (this.connection) {
            this.connection.close().then(_ => {
                console.log("Connection closed");
            }).fail(ex => this.logError(ex.message));
        }

        if (this.screenshare) {
            this.screenshare.close();
        }
    }

    public toggleLocalVideo(enabled: boolean | null = null): void {
        if (this.connection) {
            let config = this.connection.getConfig();
            const state = enabled ?? config.getLocalVideoDisabled();
            config.setLocalVideoDisabled(!state);
            this.connection.update(config);

            this.isLocalVideoEnabled = state;
            console.log("injaaaaaa", state);

        }
    }

    public toggleLocalAudio(): void {
        if (this.connection) {
            let config = this.connection.getConfig();
            // Muting instead of disabling to fix audio loop and delay issue
            config.setLocalAudioMuted(!config.getLocalAudioMuted());
            this.connection.update(config);

            this.isLocalAudioEnabled = !this.isLocalAudioEnabled;
        }
    }

    public getUserAliasById(userId: string | undefined): string | undefined {
        if (this.channel === undefined) {
            return undefined;
        }
        return this.channel.getRemoteClientInfos().find(x => x.getUserId() === userId)?.getUserAlias();
    }

    public isMe(userId: string): boolean {
        return this.client.getUserId() === userId;
    }

    private logError(error: string): void {
        console.log(error);
        this.events.notify("onerror", error);
    }
}
