type Props = {
    title: string,
    onSuccess: () => void,
    onCancel: () => void
}

export default function LeaveChannelModal({ title, onSuccess, onCancel }: Props) {
	return (
		<div id="confirmLeave" className="modal">
			<div className="modal-background" onClick={onCancel}></div>
			<div className="modal-content">
				<div className="box">
					<p className="title">{title}</p>
					<div className="buttons" style={{flexDirection: "row-reverse"}}>
						<button className="button is-danger" onClick={onCancel} style={{marginRight: "25px"}}>No</button>
						<button className="button is-success" onClick={onSuccess} style={{marginRight: "25px"}}>Yes</button>
					</div>
				</div>
			</div>
			<button className="modal-close is-large" aria-label="close" onClick={onCancel}></button>
		</div>
	);
}