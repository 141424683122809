import { useParams } from "react-router-dom";
import InviteBox from '../components/invite/InviteBox';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import httpRequest from '../js/utils/httpRequest';
import generateUserAlias from '../js/utils/UserAliasGenerator';

export default function Invite() {    
    const params = useParams();
    const [userAlias, setUserAlias] = useState("");
    const [channelId, setChannelId] = useState("");

    let navigate = useNavigate();

    useEffect(() => {
        setUserAlias(localStorage.getItem('userAlias') || generateUserAlias());

        onJoinChannelClick(params.id)

    }, []);
    

    function onJoinChannelClick(id = channelId) {
        if (!id) {
            return;
        }

        localStorage.setItem('userAlias', userAlias);

        navigate(`/channel/${id}`);
    }

    function onCreateChannelClick() {
        localStorage.setItem('userAlias', userAlias);

        httpRequest.getNewChannel(id => {
            navigate(`/channel/${id}`);
        });
    }

    return (
        <InviteBox invitedFrom={params.from} channelId={params.id} />
    );
}